.home-page {
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin: auto;
  .carousel-status {
    display: none;
  }
  .first-section {
    .carousel {
      width: 90%;
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px 10px;

      img {
        width: 100%;
        border-radius: 10px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        margin: 10px 0px;
      }
    }
  }

  .second-second {
    width: 80%;
    margin: auto;
    .carousel {
      width: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px 10px;

      img {
        width: 80%;
        border-radius: 10px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        margin: 10px 0px;
      }
    }
  }
  .about-section {
    width: 85%;
    display: flex;
    justify-content: space-between;
    margin: auto;
    opacity: 0;
    transform: translateY(50px);
    @media (max-width: "1000px") {
      flex-direction: column;
    }
    // transition: opacity 1s ease, transform 1s ease;
    h2 {
      color: #0095b8;
      font-size: 35px;
    }
    p {
      line-height: 28px;
      font-weight: 300;
      font-size: 18px;
    }
    button {
      color: white;
      background: #0095b8;
      border: none;
      padding: 10px;
      border-radius: 10px;
      width: 100px;
    }
    button:hover {
      background-color: white;
      color: #0195b8;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }
  }

  .about-section.animate {
    opacity: 1;
    transform: translateY(0);
  }

  .text-section {
    width: 58%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    transform: translateX(-100%);
    opacity: 0;
    transition: transform 1s ease, opacity 1s ease;
    @media (max-width: "1000px") {
      width: 100%;
    }
  }

  .img-section {
    width: 40%;
    transform: translateX(100%);
    opacity: 0;
    transition: transform 1s ease, opacity 1s ease;
    @media (max-width: "1000px") {
      width: 100%;
    }
    img {
      width: 100%;
    }
  }

  .text-section.animate {
    transform: translateX(0);
    opacity: 1;
  }

  .img-section.animate {
    transform: translateX(0);
    opacity: 1;
  }

  .service-section {
    padding: 40px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    .service-main {
      width: 100%;
      display: flex;
      justify-content: space-between;
      @media (max-width: "1000px") {
        flex-direction: column;
      }
      .img-container {
        width: 48%;
        @media (max-width: "1000px") {
          width: 100%;
        }

        img {
          width: 100%;
          height: 500px;
          border-radius: 10px;
        }
      }
      .Text-container {
        width: 48%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        @media (max-width: "1000px") {
          width: 100%;
        }
        .Text-main {
          display: flex;
          flex-direction: column;
          gap: 15px;
          h2 {
            color: #0195b8;
            font-size: 35px;
          }
          p {
            color: #353535;
            font-weight: 300;
          }
        }
        .Text-icons {
          display: flex;
          flex-direction: column;
          gap: 10px;
          .icons {
            padding: 10px;
            width: 100%;
            background-color: #0195b8;
            display: flex;
            gap: 15px;
            align-items: center;
            color: #fff;
            border-radius: 10px;
            font-weight: 600;
            cursor: pointer;

            .icon {
              width: 40px;
              height: 40px;
            }
          }
          .icons:hover {
            background-color: white;
            color: #0195b8;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
          }
        }
      }
    }
    .Text-icons .icons {
      opacity: 0;
      transform: translateX(100%);
      transition: transform 0.5s ease, opacity 0.5s ease;
    }

    .Text-icons .icons.animate {
      opacity: 1;
      transform: translateX(0);
    }
  }
}
