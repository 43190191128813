.service-content {
  display: flex;
  padding: 50px 50px 50px 50px;
  .talend-div,
  .staffing-service-div,
  .application-development-div,
  .salesforce-development-div {
    width: 100% !important;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media (max-width: 800px) {
      width: 100%;
    }
    .heading-card {
      h1 {
        font-size: 30px;
        font-family: "Montserrat", sans-serif;
        color: #0095b8;
      }
      p {
        line-height: 27px;
        color: #6f6f6f;
        font-size: 18px;
      }
    }
  }
  .application-development-div {
    .points-card {
      .each-content-points {
        h3 {
          font-size: 24px;
          color: #0095b8;
          padding-bottom: 10px;
          margin-bottom: 5px;
        }
        p {
          line-height: 27px;
          color: #6f6f6f;
          font-size: 18px;
          margin: 0px;
        }
        .all-points-card {
          div {
            display: flex;
            align-items: center;
            gap: 5px;
            .angle-icon {
              color: #0095b8;
            }
            p {
              color: #6f6f6f;
              line-height: 30px;
              font-size: 18px;
              font-weight: 500;
              margin: 7px;
            }
          }
        }
      }
    }
    p {
      line-height: 27px;
      color: #6f6f6f;
      font-size: 18px;
    }
  }
  .salesforce-development-div {
    .all-points-card {
      div {
        display: flex;
        align-items: center;
        gap: 5px;
        .angle-icon {
          color: #0095b8;
        }
        p {
          color: #6f6f6f;
          line-height: 30px;
          font-size: 18px;
          font-weight: 500;
          margin: 7px;
        }
      }
    }
    .sub-heading {
      h3 {
        font-size: 24px;
        color: #0095b8;
        padding-bottom: 10px;
        margin-bottom: 5px;
      }
      p {
        line-height: 27px;
        color: #6f6f6f;
        font-size: 18px;
        margin: 0px;
      }
    }
  }
  .staffing-service-div {
    .sub-heading {
      h3 {
        font-size: 24px;
        color: #0095b8;
        padding-bottom: 10px;
        margin-bottom: 5px;
      }
      p {
        line-height: 27px;
        color: #6f6f6f;
        font-size: 18px;
        margin: 0px;
      }
    }
    h4 {
      font-size: 24px;
      color: #0095b8;
      padding-bottom: 10px;
      margin-bottom: 0px;
    }
    .all-staffing-points {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .single-main-div {
        h5 {
          line-height: 27px;
          color: #6f6f6f;
          font-size: 18px;
          margin: 0px;
          margin-bottom: 5px;
        }
        div {
          display: flex;
          align-items: center;
          gap: 5px;
          .angle-icon {
            color: #0095b8;
          }
          p {
            color: #6f6f6f;
            line-height: 30px;
            font-size: 18px;
            font-weight: 500;
            margin: 7px;
          }
        }
      }
    }
  }
  .talend-div {
    h2 {
      font-size: 24px;
      color: #0095b8;
      padding-bottom: 10px;
      margin-bottom: 5px;
    }
    p {
      line-height: 27px;
      color: #6f6f6f;
      font-size: 18px;
      margin: 0px;
    }
  }
  .right-div {
    width: 30%;
    @media (max-width: 800px) {
      display: none;
    }
  }
  gap: 100px;
}
