*{
  margin: 0px ;
  padding: 0px ;
  box-sizing: border-box;
  
}
P{
  margin: 0px;
  padding: 0px;
}

