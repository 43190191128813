.footer-component {
  width: 90%;
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  padding: 40px;
  gap: 50px;
  background: #f2f2f2;
  margin: auto;
  margin-top: 50px;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
  img {
    width: 100%;
  }
  iframe {
    width: 100%;
    height: 400px;
    @media (max-width: 1000px) {
      height: 200px;
    }
  }
  .footer1 {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .contact-details {
      display: flex;
      flex-direction: column;
      gap: 10px;
      a {
        display: flex;
        align-items: center;
        gap: 10px;
        text-decoration: none;
        color: #000;
        font-size: 15px;
        div {
          color: #0095b8;
        }
      }
    }
    .logos-container {
      display: flex;
      gap: 10px;
      a {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #0095b8;
        display: flex;
        align-items: center;
        justify-content: center;
        div {
          color: #f2f2f2;
        }
      }
    }
  }
  h3 {
    padding-bottom: 20px;
    color: #0095b8;
    font-size: 22px;
  }
}
.footer-ending {
  width: 90%;
  margin: auto;
  background-color: #0095b8;
  padding: 10px 40px;
  color: #f2f2f2;
}
