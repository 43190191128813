.contact-page {
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin: auto;
  .main-img-section {
    width: 100%;
    position: relative;
    .main-img-text {
      position: absolute;
      top: 40%;
      left: 10%;
      color: #ffffff;
      font-size: 40px;
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      background: rgba(3, 89, 109, 0.6);
      padding: 20px;
      border-radius: 20px;
    }
    img {
      width: 100%;
    }
  }
  .cards-container {
    width: 70%;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin: auto;
    @media (max-width: "1200px") {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }
    @media (max-width: 450px) {
      width: 80%;
    }
    @media (max-width: 370px) {
      width: 90%;
    }

    .card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 60px 30px;
      background: #0095b8;
      border-radius: 20px;
      gap: 10px;
      color: #fff;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      transition: transform 0.3s ease, background-color 0.3s ease,
        box-shadow 0.3s ease;
      @media (max-width: 1200px) {
        width: 48%;
      }
      @media (max-width: 800px) {
        width: 70%;
      }
      @media (max-width: 630px) {
        width: 90%;
      }
      @media (max-width: 520px) {
        width: 100%;
      }
      p {
        text-align: center;
      }
    }
    .card:hover {
      background-color: #fff;
      color: #0095b8;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      transition: transform 0.3s ease, background-color 0.3s ease,
        box-shadow 0.3s ease;
    }
  }
}
