.main-img-section {
  width: 100%;
  position: relative;
  @media (max-width: 700px) {
    height: 250px;
  }
  @media (max-width: 500px) {
    height: 300px;
  }
  .main-img-text {
    position: absolute;
    top: 35%;
     left: 10%;
    color: #ffffff;
    font-size: 40px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    background: rgba(3, 89, 109, 0.6);
    padding: 20px;
    border-radius: 20px;
    @media (max-width: 700px) {
      padding: 20px;
      font-size: 25px;
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
