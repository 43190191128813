.services-links {
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  .links-card {
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 100%;
    color: #0095b8;
    div {
      cursor: pointer;
      display: flex;
      align-items: center;
      background-color: #eaeaea;
      padding: 15px 15px;
      gap: 5px;
      p {
        margin: 0px;
        font-size: 14px;
        font-weight: 500;
        margin: 0px;
      }
    }
    .active-card {
      background-color: #0095b8;
      color: #fff;
    }
  }
  .email-card {
    background-color: #eaeaea;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    cursor: pointer;

    .email-image {
      height: 100px;
      width: 100px;
      color: #0095b8;
    }
    h2 {
      font-size: 20px;
      color: #0095b8;
      margin: 0px;
    }
  }
}
