.services {
  .features-container {
    padding: 40px 20px;
    display: flex;
    gap: 20px;
    @media (max-width: 800px) {
      flex-direction: column;
    }
    .image-card {
      width: 50%;
      @media (max-width: 800px) {
        width: 100%;
      }
      img {
        height: 650px;
        width: 100%;
        border-radius: 20px;
      }
    }
    .services-features-card {
      width: 50%;
      @media (max-width: 800px) {
        width: 100%;
      }
      .services-sub-heading {
        h3 {
          margin: 0px;
          color: #0095b8;
          font-size: 35px;
          padding-bottom: 10px;
        }
        p {
          margin: 0px;
          color: #353535;
        }
      }
      .features-list {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        div {
          border-radius: 7px;
          padding: 10px 20px;
          display: flex;
          align-items: center;
          gap: 20px;
          background-color: #0095b8;
          color: #fff;
          font-weight: 500;
          cursor: pointer;
          .service-image {
            height: 50px;
            width: 50px;
          }
        }
        div:hover {
          background-color: #fff;
          color: #0095b8;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }
      }
    }
  }
}
