.about-page {
  width: 90%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
  gap: 50px;
  .main-img-section {
    width: 100%;
    position: relative;
    .main-img-text {
      position: absolute;
      top: 40%;
      left: 10%;
      color: #ffffff;
      font-size: 40px;
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      background: rgba(3, 89, 109, 0.6);
      padding: 20px;
      border-radius: 20px;
    }
    img {
      width: 100%;
    }
  }
  .digital-container {
    display: flex;
    width: 95%;
    gap: 4%;
    .side-img-container {
      width: 48%;
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 5px;
      @media (max-width: "1200px") {
        display: none;
      }

      .side-img {
        width: 100%;
      }
    }
    .side-text-container {
      width: 48%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      h3 {
        font-size: 48px;
        text-align: center;
      }
      p {
        line-height: 26px;
        font-size: 18px;
      }
      @media (max-width: "1150px") {
        width: 100%;
        align-items: center;
        p {
          text-align: center;
        }
      }
    }
  }
  .img-text-main {
    display: flex;
    width: 90%;
    align-items: center;
    justify-content: center;
    gap: 50px;
    margin: auto;
    @media (max-width: "1120px") {
      flex-direction: column;
      justify-content: start;
      align-items: center;
    }
    .img-text-container {
      display: flex;
      flex-direction: column;
      gap: 50px;
      align-items: flex-start;
    }

    // .img-text-container {
    //   width: 30%;
    // }
  }
  .img-text {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 30px;
    @media (max-width: "1120px") {
      flex-direction: row-reverse;
    }
  }
  .img-text2 {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 30px;

    .text2 {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
  .core-section {
    width: 90%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 50px;
    .core-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 20px;
      h3 {
        font-size: 35px;
      }
      p {
        text-align: center;
        font-size: 18px;
        line-height: 26px;
      }
    }
    .text1 {
      display: flex;
      align-items: end;
      flex-direction: column;
      gap: 10px;
      @media (max-width: "1120px") {
        align-items: start;
      }
      P {
        text-align: end;
        @media (max-width: "1120px") {
          text-align: start;
        }
      }
      h4 {
        @media (max-width: "1120px") {
          text-align: start;
        }
      }
    }
  }
  P {
    font-weight: 300;
  }
  h3,
  h4 {
    color: #0095b8;
  }
}
