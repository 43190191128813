.career-page {
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin: auto;
  .main-img-section {
    width: 100%;
    position: relative;
    .main-img-text {
      position: absolute;
      top: 40%;
      left: 10%;
      color: #ffffff;
      font-size: 40px;
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      background: rgba(3, 89, 109, 0.6);
      padding: 20px;
      border-radius: 20px;
    }
    img {
      width: 100%;
    }
  }
  .job-box-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 40px;
  }
  .job-container {
    width: 60%;
    display: flex;

    flex-direction: column;
    gap: 20px;
    @media (max-width: "1240px") {
      width: 70%;
    }
    @media (max-width: "1000px") {
      width: 80%;
    }
    @media (max-width: "500px") {
      width: 80%;
    }
    .job-containers {
      width: 100%;
      display: flex;

      flex-direction: column;
      gap: 20px;
    }
    .job-box-active {
      display: flex;
      align-items: center;
      justify-content: space-between;

      padding: 10px 15px;
      background-color: #0095b8;
      color: #ffffff;
    }
    .job-box {
      display: flex;
      align-items: center;
      justify-content: space-between;

      padding: 10px 15px;
      background-color: #eaeaea;
      color: #0095b8;
      P {
        font-weight: 300;
      }
    }
    .heading-text {
      margin-bottom: 10px;
    }
  }
}
